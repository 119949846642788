/* eslint-disable @typescript-eslint/promise-function-async */
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { TelemetryService } from '@bp/frontend/services/telemetry';
import {
	NewsKind, NewsroomSectionKind, NEWS_SUMMARY_APPEARANCE, NewsSummaryAppearance, INewsroomRouteData
} from '@bp/frontend/domains/newsroom/models';
import { RouteDataMetatags } from '@bp/frontend/features/metatags/models';

import { HomePageComponent } from '@bp/promo/sections/home';
import { GetStartedModalComponent } from '@bp/promo/shared/components/get-started-modal';

import { ModalMenuComponent } from './core';

// App routing module is for lazy routes and modals

const routes: Routes = [
	{
		path: 'menu',
		component: ModalMenuComponent,
		outlet: 'modal',
	},
	{
		path: 'get-started',
		component: GetStartedModalComponent,
		outlet: 'modal',
	},
	{
		path: '',
		component: HomePageComponent,
	},
	{
		path: 'about-us',
		loadChildren: () => import('@bp/promo/sections/about-us')
			.then(m => m.AboutUsModule),
	},
	{
		path: 'book-demo-calendar',
		loadChildren: () => import('@bp/promo/sections/book-demo-calendar')
			.then(m => m.BookDemoCalendarModule),
	},
	{
		path: 'bug-bounty-program',
		loadChildren: () => import('@bp/promo/sections/bug-bounty-program')
			.then(m => m.BugBountyProgramModule),
	},
	{
		path: 'product',
		loadChildren: () => import('@bp/promo/sections/product')
			.then(m => m.ProductModule),
	},
	{
		path: 'usecases',
		loadChildren: () => import('@bp/promo/sections/usecases')
			.then(m => m.UsecasesModule),
	},
	{
		path: 'bridger-retry',
		loadChildren: () => import('@bp/promo/sections/retry')
			.then(m => m.RetryModule),
	},
	{
		path: 'omnichannel',
		loadChildren: () => import('@bp/promo/sections/omni-channel')
			.then(m => m.OmniChannelModule),
	},
	{
		path: 'paywith',
		loadChildren: () => import('@bp/promo/sections/paywith')
			.then(m => m.PayWithModule),
	},
	{
		path: 'pci',
		loadChildren: () => import('@bp/promo/sections/pci')
			.then(m => m.PciModule),
	},
	{
		path: 'cookies-policy',
		loadChildren: () => import('@bp/promo/sections/cookies-policy')
			.then(m => m.CookiesPolicyModule),
	},
	{
		path: 'connections',
		loadChildren: () => import('@bp/promo/sections/psp-connections')
			.then(m => m.PspConnectionsModule),
	},
	{
		path: 'payment-operations',
		loadChildren: () => import('@bp/promo/sections/payment-operations')
			.then(m => m.PaymentOperationsModule),
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('@bp/promo/sections/privacy-policy')
			.then(m => m.PrivacyPolicyModule),
	},
	{
		path: NewsroomSectionKind.newsroom.routePath,
		data: <INewsroomRouteData & RouteDataMetatags>{
			newsroomSection: NewsroomSectionKind.newsroom,
			availableNewsKinds: [ NewsKind.pressRelease, NewsKind.update, NewsKind.feature ],
			title: 'Newsroom, the Latest News on Our Product',
			metatags: {
				description: 'Read the latest news, press releases and announcements from BridgerPay.',
			},
		},
		providers: [
			{
				provide: NEWS_SUMMARY_APPEARANCE,
				useValue: <NewsSummaryAppearance>'compact',
			},
		],
		loadChildren: () => import('@bp/promo/sections/newsroom')
			.then(m => m.NewsroomModule),
	},
	{
		path: NewsroomSectionKind.bridgerStories.routePath,
		data: <INewsroomRouteData & RouteDataMetatags> {
			newsroomSection: NewsroomSectionKind.bridgerStories,
			availableNewsKinds: [ NewsKind.caseStudy ],
			title: 'Customer Success Stories and Case Studies',
			metatags: {
				description: 'Case studies and Success Stories to discover how BridgerPay is empowering businesses across all verticals to scale faster by allowing them to automate their payment flows.',
			},
		},
		loadChildren: () => import('@bp/promo/sections/newsroom')
			.then(m => m.NewsroomModule),
	},
	{
		path: NewsroomSectionKind.blog.routePath,
		data: <INewsroomRouteData & RouteDataMetatags>{
			newsroomSection: NewsroomSectionKind.blog,
			availableNewsKinds: [ NewsKind.blogPost ],
			title: 'Blog',
			metatags: {
				description: 'Read the latest updates from BridgerPay, learn about payment operations, how to boost your approval rate, and create the ultimate payment experience.',
			},
		},
		providers: [
			{
				provide: NEWS_SUMMARY_APPEARANCE,
				useValue: <NewsSummaryAppearance>'compact',
			},
		],
		loadChildren: () => import('@bp/promo/sections/newsroom')
			.then(m => m.NewsroomModule),
	},
	{
		path: 'learn',
		loadChildren: () => import('@bp/promo/sections/bridger-events')
			.then(m => m.BridgerEventsModule),
	},
	{
		path: 'prohibited-businesses',
		loadChildren: () => import('@bp/promo/sections/prohibited-businesses')
			.then(m => m.ProhibitedBusinessesModule),
	},
	// {
	// 	path: 'security',
	// 	loadChildren: () => import('@bp/promo/sections/security')
	// 		.then(m => m.SecurityModule),
	// },
	{
		path: 'terms-of-service',
		loadChildren: () => import('@bp/promo/sections/terms-of-service')
			.then(m => m.TermsOfServiceModule),
	},
	{
		path: 'thank-you',
		loadChildren: () => import('@bp/promo/sections/thank-you')
			.then(m => m.ThankYouModule),
	},
	{
		path: 'careers',
		loadChildren: () => import('@bp/promo/sections/careers')
			.then(m => m.CareersModule),
	},
	{
		path: 'landings',
		loadChildren: () => import('@bp/promo/landings')
			.then(m => m.LandingsRoutingModule),
		data: {
			landing: true,
		},
	},
	{
		path: 'subscription-terms',
		loadChildren: () => import('@bp/promo/sections/subscription-terms')
			.then(module => module.SubscriptionTermsModule),
	},
];

@NgModule({
	imports: [
		QuicklinkModule,
		RouterModule.forRoot(routes, {
			enableTracing: false,
			initialNavigation: 'enabledNonBlocking',
			paramsInheritanceStrategy: 'always',
			onSameUrlNavigation: 'reload',
			canceledNavigationResolution: 'replace',
			preloadingStrategy: QuicklinkStrategy,
			errorHandler: TelemetryService.routerErrorHandler,
		}),
	],
	exports: [ RouterModule, QuicklinkModule ],
})
export class AppRoutingModule { }
