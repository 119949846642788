import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'bp-companies',
	templateUrl: './companies.component.html',
	styleUrls: [ './companies.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesComponent {

	protected readonly _companiesTiles = [
		'namastay',
		'one-two-trip',
		'james-allen-short',
		'passportcard',
		'blue-nile',
		'sportsevents',
		'zalora',
		'paybis',
		'israel-canada',
		'fattal',
		'marabu',
		'waves',
		'luxury-escapes-two-lines',
		'newton',
		'calypsa',
		'888-casino',
		'leonardo',
		'nyx',
	];

}
