<div class="logo-section">
	<a mat-button class="logo-btn" color="primary" routerLink="/">
		<bp-svg-icon name="logo" />
	</a>
</div>

<div class="left-section">
	<div>
		<span>Company</span>

		<a mat-button routerLink="/product">Product</a>

		<a mat-button routerLink="/bridger-retry">Bridger Retry™</a>

		<a mat-button routerLink="/paywith">Paywith™</a>

		<a mat-button routerLink="/about-us">About Us</a>

		<a mat-button routerLink="/newsroom">Newsroom</a>

		<a mat-button routerLink="/careers">
			<div class="text-left">Careers</div>

			<bp-we-are-hiring-badge />
		</a>

		<a mat-button routerLink="/omnichannel">Omnichannel</a>
	</div>
</div>

<div class="main-section flex-align-items-center">
	<p data-nosnippet class="description text-center">
		BridgerPay is the world’s first payment operations platform, built to automate ALL payment flows, empowering ANY
		business.
	</p>

	<div class="socials">
		<a href="https://www.linkedin.com/company/bridgerpay/" bpTargetBlank>
			<bp-svg-icon name="linkedin-white" />
		</a>

		<a href="https://www.facebook.com/bridgerpay/" bpTargetBlank>
			<bp-svg-icon name="facebook-white" />
		</a>

		<a href="https://www.instagram.com/bridgerpay/" bpTargetBlank>
			<bp-svg-icon name="instagram-white" />
		</a>

		<a href="https://www.youtube.com/channel/UCZvt3egNCg7rMnaCwuldW4Q" bpTargetBlank>
			<bp-svg-icon name="youtube-white" />
		</a>
	</div>

	<div class="address text-center mb-2">Eleftherias street, 153 Limassol, 3042, Cyprus</div>

	<div class="address text-center mb-2">Derech Menachem Begin 144, Tel Aviv-Yafo, 6492102, Israel</div>

	<a mat-button class="contact-email text-center mb-2" href="mailto:hello@bridgerpay.com">hello&#64;bridgerpay.com</a>
</div>

<div class="right-section">
	<div>
		<span>Resources</span>

		<a mat-button routerLink="/learn">Learn</a>

		<a mat-button routerLink="/usecases">Use Cases</a>

		<a mat-button href="https://developers.bridgerpay.com" bpTargetBlank>Developers</a>

		<a mat-button href="https://help.bridgerpay.com/">Help center</a>

		<a mat-button routerLink="/payment-operations">Payment Operations</a>

		<a mat-button routerLink="/pci">PCI & Security</a>

		<button mat-button type="button" class="termly-display-preferences">Privacy control center</button>
	</div>
</div>

<div class="trust-logos-wrapper">
	<div class="trust-logos">
		<bp-picture *ngFor="let logo of _trustLogosDefs$ | async" [src]="logo.src" [alt]="logo.alt" [svg]="logo.svg" />
	</div>
</div>

<div data-nosnippet class="workflow-description">
	BridgerPay is not a PSP (payment service provider), or an acquiring service, and we do not provide any processing
	merchant accounts. Bridger is a SaaS (software-as-a-service) company that allows businesses to utilise one API to
	consume all payments from any method or provider that is connected within BridgerPay’s ecosystem.
</div>

<div class="misc-section flex-align-items-center">
	<div class="mat-small">
		All Rights Reserved © BridgerPay

		<bp-app-version />
	</div>

	<div class="misc-links">
		<a
			*ngFor="let page of _legalPages; let last = last"
			class="link use-current-color bottom-bordered mat-small"
			routerLink="/{{ page.kebabCase }}"
			[class.mr-4]="!last"
		>
			{{ page.displayName }}
		</a>
	</div>
</div>

<bp-developer-overlay-panel class="service-section ml-auto" />
