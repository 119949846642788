<bp-modal hideCloseBtn>
	<ng-template>
		<nav class="main-menu">
			<a mat-button routerLinkNoOutlets="product" routerLinkActive="active"> Product </a>

			<a mat-button routerLinkNoOutlets="bridger-retry" routerLinkActive="active"> Retry™ </a>

			<a mat-button routerLinkNoOutlets="omnichannel" routerLinkActive="active">
				<span class="relative">
					Omnichannel

					<bp-new-label />
				</span>
			</a>

			<a mat-button routerLinkNoOutlets="connections" routerLinkActive="active">Connections</a>

			<bp-company-submenu class="togglable-submenu" />

			<a mat-button routerLinkNoOutlets="learn" routerLinkActive="active"> Learn </a>
		</nav>

		<nav class="bottom-menu">
			<bp-call-to-action-button
				classList="promo-flat-button get-started-btn"
				bpGoogleTagOnClick="promo_modal-menu_cta_click"
			/>

			<div class="text-center">
				<a class="link" href="https://dash.bridgerpay.com" bpTargetBlank>Log in</a>
			</div>
		</nav>
	</ng-template>
</bp-modal>
