import { Directive, forwardRef, inject, InjectionToken, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { uuid } from '@bp/shared/utilities/core';

import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';

import { IModalHostComponent } from './modal-host-component.interface';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';

export interface IModalHostComponentDefaultConfig {
	disableClose?: boolean;
}

export const MODAL_HOST_COMPONENT_DEFAULT_CONFIG = new InjectionToken<IModalHostComponentDefaultConfig>('MODAL_HOST_DISABLE_CLOSE_TOKEN');

@Directive()
export abstract class ModalHostComponent extends Destroyable implements IModalHostComponent {

	protected readonly _modalService = inject(ModalService);

	private readonly __defaultConfig = inject(MODAL_HOST_COMPONENT_DEFAULT_CONFIG, { optional: true });

	readonly id: string = uuid();

	readonly panelClass?: string[];

	readonly disableClose?: boolean;

	@ViewChild(forwardRef(() => ModalComponent), { static: true })
	readonly modal!: ModalComponent;

	protected _dialogRef?: MatDialogRef<any>;

	constructor() {
		super();

		this.__findDialogRefByIdAndSetIt();

		this.disableClose ??= this.__defaultConfig?.disableClose;
	}

	closeModal({ skipCanCloseCheck }: { skipCanCloseCheck?: boolean } = {}): void {
		void this._modalService.tryCloseModal({ skipCanCloseCheck });
	}

	private __findDialogRefByIdAndSetIt(): void {
		this._modalService
			.getOpenModalById$(this.id)
			.pipe(takeUntilDestroyed(this))
			.subscribe(dialog => (this._dialogRef = dialog));
	}

}
