<mat-toolbar>
	<a mat-button class="logo-btn" color="primary" routerLink="/">
		<bp-svg-icon name="logo-short-text" />
	</a>

	<div class="ml-2 middle-section">
		<a mat-button routerLink="/product" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
			<span>Product</span>
		</a>

		<a
			mat-button
			routerLink="/bridger-retry"
			class="retry"
			routerLinkActive="active"
			[routerLinkActiveOptions]="{ exact: true }"
		>
			<span>Retry™</span>
		</a>

		<a
			mat-button
			routerLink="/omnichannel"
			class="retry"
			routerLinkActive="active"
			[routerLinkActiveOptions]="{ exact: true }"
		>
			<span>Omnichannel</span>

			<bp-new-label />
		</a>

		<a
			mat-button
			routerLink="/connections"
			class="relative"
			routerLinkActive="active"
			[routerLinkActiveOptions]="{ exact: true }"
		>
			<span>Connections</span>
		</a>

		<bp-company-submenu dropdownMode />

		<a mat-button routerLink="/learn" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
			<span>Learn</span>
		</a>
	</div>

	<div class="right-section ml-auto">
		<bp-call-to-action-button
			classList="get-started-btn promo-flat-button promo-sm-button mr-4"
			bpGoogleTagOnClick="promo_header_cta_click"
		/>

		<a class="link login-btn" bpTargetBlank href="{{ environment.merchantAdminOrigin }}"> Log in </a>

		<bp-burger-btn
			class="menu-btn bp-icon-button-lg"
			[showCross]="(mobileMenuDialog$ | async) !== undefined"
			(click)="mobileMenuDialog$.value ? mobileMenuDialog$.value.close() : openMobileMenu()"
		/>
	</div>
</mat-toolbar>
